import { Box, Button, Typography } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import { ref, getBlob } from 'firebase/storage';
import { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LoadingWrapper from '../../components/LoadingWrapper';
import Markdown from '../../components/Markdown';
import PageContainer from '../../components/PageContainer/PageContainer';
import { storage, functions } from '../../helpers/Firebase';
import useEpisodeQuery from '../../hooks/useEpisodeQuery';

const EpisodeAssistant = () => {
  const { podcastId, episodeId } = useParams();
  const [t] = useTranslation();
  const [transcript, setTranscript] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [autoRefetch, setAutoRefetch] = useState(true);
  const [promptResult, setPromptResult] = useState('');

  const transcribeAudio = httpsCallable(
    functions,
    'transcribeAudioFile-uiEndpoint',
  );

  const query = useEpisodeQuery(
    {
      podcastId,
      episodeId,
    },
    {
      refetchInterval: autoRefetch ? 1000 : false,
    },
  );

  useEffect(() => {
    if (query.data) {
      const transcript = query.data?.data()?.transcript;
      switch (transcript?.status) {
        case 'done':
          const pathReference = ref(storage, transcript.gcsUri);
          getBlob(pathReference).then((data) =>
            data.text().then((data) => {
              setTranscript(
                JSON.parse(data).results.map((result) =>
                  result.alternatives.map(
                    (alternative) => alternative.transcript,
                  ),
                ),
              );
              setAutoRefetch(false);
              setIsLoading(false);
            }),
          );
          break;
        case 'processing':
          // Do nothing
          break;
        case 'error':
          setTranscript(transcript.errorMessage);
          setAutoRefetch(false);
          setIsLoading(false);
          break;
        default:
          setAutoRefetch(false);
          setIsLoading(false);
      }
    }
  }, [query.data]);

  return (
    <PageContainer title={t('EpisodeAssistant.title')}>
      <Typography gutterBottom>{t('EpisodeAssistant.betaIntro')}</Typography>
      <Typography variant="h3" gutterBottom>
        {t('EpisodeAssistant.transcript.headline')}
      </Typography>
      <LoadingWrapper
        isLoading={isLoading}
        isSuccess={!isLoading}
        isError={query.isError}
      >
        <Box>
          {transcript ? (
            <>
              <CopyToClipboard text={transcript}>
                <Button>Abschrift kopieren</Button>
              </CopyToClipboard>
              <Markdown text={promptResult} />
              <Button
                onClick={async () => {
                  const runPrompt = httpsCallable(
                    functions,
                    'genai-runEpisodePrompt',
                  );

                  const result = await runPrompt({ podcastId, episodeId });

                  setPromptResult(result.data);
                }}
              >
                Titel erstellen
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={async () => {
                  await transcribeAudio({ podcastId, episodeId });

                  setIsLoading(true);
                  setAutoRefetch(true);
                }}
              >
                {t('EpisodeAssistant.button.transcribe')}
              </Button>
            </>
          )}
        </Box>
      </LoadingWrapper>
    </PageContainer>
  );
};

export default EpisodeAssistant;
